const getInicioFinSemanaActual = () => {
    const hoy = new Date();
    const diaSemana = hoy.getDay(); // 0 (Domingo) a 6 (Sábado)
  
    // Calcular el inicio de la semana (Lunes)
    const inicioSemana = new Date(hoy);
    inicioSemana.setDate(hoy.getDate() - (diaSemana === 0 ? 6 : diaSemana - 1));
    inicioSemana.setHours(0, 0, 0, 0); // Establecer a medianoche
  
    // Calcular el final de la semana (Domingo)
    const finSemana = new Date(inicioSemana);
    finSemana.setDate(inicioSemana.getDate() + 6);
    finSemana.setHours(23, 59, 59, 999); // Establecer al final del día
  
    return {
      inicioSemana,
      finSemana,
    };
  };
  
  export default getInicioFinSemanaActual;
  