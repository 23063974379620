import React, { useState } from 'react';

import "./AsesoresTable.scss"

const AsesoresTable = ({ asesoresDB, asesorSeleccionado, setAsesorSeleccionado, small=true }) => {

  return (
    <div className={`asesores-table-container ${small ? "small" : ""}`}>
      <table className="asesores-table">
        <thead>
          <tr>
            <th>CUA</th>
            <th>Nombre</th>
            <th>Estado</th>
            <th>Telefono</th>
          </tr>
        </thead>
        <tbody>
          {asesoresDB.map((asesor) => (
            <tr 
              className={`${asesorSeleccionado?.uuid == asesor?.uuid ? "seleccionado" : ""}`}
              key={asesor.uuid} 
              onClick={() => setAsesorSeleccionado(asesor)}
            >
              <td>{asesor.cua}</td>
              <td>{asesor.nombre}</td>
              <td>{asesor.address_estado}</td>
              <td>{asesor.telefono}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AsesoresTable;