import { useEffect, useRef, useState } from "react";

// Components
import AppPageTemplate from "../../Components/AppPageTemplate/AppPageTemplate";
import TablaRealizados from "../../Components/TablaRealizados/TablaRealizados";

//imgs
import isotipo from '../../Images/IsotipoBlanco.png';


// db connection
import SteinStore from "stein-js-client";

//Styles
import "./PuntosLayout.scss"

//util
import obtenerSemanas from "../../Utils/obtenerSemanas";
import AsesorView from "../AsesorView/AsesorView";
import ManagerView from "../ManagerView/ManagerView";




export default function({
  title,
  cua,
  asesorData,
  basesDeDatos
}) {

  


  




  
  return (<main className="PuntosLayout">
    {/* aqui va el header */}
    <header className="Header" onClick={()=> window.location.href = "https://www.valoret.com.mx"}><img src={isotipo}/></header>
    <div style={{height: "40px"}} />




    
    
    {/* aca iria la vista del director donde ve todos los asesores y despues pasa a su vista de asesor */}
    {
      asesorData?.asesorOrigen == "gerentes" &&
      <ManagerView 
        title={title}
        cua={cua}
        asesorData={asesorData}
        basesDeDatos={basesDeDatos}
      />
    }
    
    
    
    
    
    
    {/* vista de asesor */}
    {
      asesorData?.asesorOrigen == "asesores" &&
      <AsesorView
        title={title}
        cua={cua}
        asesorData={asesorData}
        basesDeDatos={basesDeDatos}
      />
    }







    <div style={{height: "60px"}} />
  </main>);
}