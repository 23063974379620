function formatearFecha(fecha) {
  const dia = String(fecha.getDate()).padStart(2, '0');
  const mes = String(fecha.getMonth() + 1).padStart(2, '0');
  const anio = fecha.getFullYear();
  return `${dia}/${mes}/${anio}`;
}

const obtenerSemanaActual = () => {
  const hoy = new Date();
  const diaSemana = hoy.getDay(); // 0 (Domingo) a 6 (Sábado)

  // Calcular el inicio de la semana (Lunes)
  const inicioSemana = new Date(hoy);
  inicioSemana.setDate(hoy.getDate() - (diaSemana === 0 ? 6 : diaSemana - 1));
  inicioSemana.setHours(0, 0, 0, 0); // Establecer a medianoche

  // Calcular el final de la semana (Domingo)
  const finSemana = new Date(inicioSemana);
  finSemana.setDate(inicioSemana.getDate() + 6);
  finSemana.setHours(23, 59, 59, 999); // Establecer al final del día

  return `${formatearFecha(inicioSemana)}-${formatearFecha(finSemana)}`;
};


export default function obtenerSemanas(semana) {
  if (semana === 'actual') {
    return obtenerSemanaActual();
  }

  const semanas = [];
  const hoy = new Date();
  let inicioSemana = new Date(hoy.setDate(hoy.getDate() - hoy.getDay() + 1)); // lunes

  for (let i = 0; i < 5; i++) {
    const finSemana = new Date(inicioSemana);
    finSemana.setDate(finSemana.getDate() + 6); // domingo
    semanas.push(`${formatearFecha(inicioSemana)}-${formatearFecha(finSemana)}`);
    inicioSemana.setDate(inicioSemana.getDate() - 7); // mover al lunes de la semana anterior
  }

  return semanas;
}