import React, { useEffect, useState } from 'react';

// styles
import './TablaRealizados.scss';

// db connection
import SteinStore from "stein-js-client";

// pop up library
import Swal from 'sweetalert2';

// utils
import obtenerSemanas from '../../Utils/obtenerSemanas';

//Random id
import { v4 as uuid } from "uuid";

const initialData = {
  lunes: { prospectos: 0, citas: 0, entrevistas: 0, solicitudes: 0, pagos: 0 },
  martes: { prospectos: 0, citas: 0, entrevistas: 0, solicitudes: 0, pagos: 0 },
  miercoles: { prospectos: 0, citas: 0, entrevistas: 0, solicitudes: 0, pagos: 0 },
  jueves: { prospectos: 0, citas: 0, entrevistas: 0, solicitudes: 0, pagos: 0 },
  viernes: { prospectos: 0, citas: 0, entrevistas: 0, solicitudes: 0, pagos: 0 }
};

const points = {
  prospectos: 3,
  citas: 3,
  entrevistas: 2,
  solicitudes: 3,
  pagos: 5
};










const TablaRealizados = ({ managerMonitoring, asesorData, realizados, setRealizados, RealizadosDB, setRealizadosDB, selectedWeek }) => {
  // DB 
  const PuntosAPI = new SteinStore(
    "https://api.steinhq.com/v1/storages/6667a2ee4d11fd04f0024c62"
  );
  const [weekHeaders, setHeadersWeek] = useState({})
  const [strictFirstRender, setStrictFirstRender] = useState(1)




  // aqui subimos los cambios a la base de datos, necesitamos la data del asesor y los realizados del día modificado
  const handleBlur = (e, day, type) => {
    if (!managerMonitoring) {
      const value = parseInt(e.target.value, 10) || 0;

      //hacemos el update
      const loadingSwal = Swal.fire({
        title: 'Enviando registro...',
        text: 'Por favor espere un momento',
        icon: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCloseButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      })


      PuntosAPI
        .edit(
          "Realizados",
          {
            search: { semana_uuid: weekHeaders.uuid },
            set: { [day]: JSON.stringify({ ...realizados[day], [type]: value }) },
            authentication: { username: process.env.REACT_APP_API_REALIZADOS_USERNAME, password: process.env.REACT_APP_API_REALIZADOS_PASSSWORD }
          }
        )
        .then(data => {
          loadingSwal.close()
        })
        .catch(data => {
          Swal.fire({ // Pop up de error
            title: 'Ha ocurrido un error',
            icon: 'error',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCloseButton: false,
          })
        })
    }

  };


  const handleChange = (e, day, type) => {
    if (!managerMonitoring) {
      const value = parseInt(e.target.value, 10) || 0;
      setRealizados({
        ...realizados,
        [day]: {
          ...realizados[day],
          [type]: value
        }
      });
    }
  };








  const calculatePoints = (dayData) => {
    return Object.keys(dayData).reduce((total, key) => total + (dayData[key] * points[key]), 0);
  };
  const getPointsName = (idx) => {
    const shortName = Object.keys(points)[idx]

    switch (shortName) {
      case "prospectos": return "Prospectos calificados";
      case "citas": return "Cita agendada";
      case "entrevistas": return "Entrevistas de primera vez";
      case "solicitudes": return "Solicitudes ingresadas";
      case "pagos": return "Pagos";
    }
  }
  const getPointsColor = (puntos) => {
    const _puntos = parseInt(puntos)
    if (_puntos < 21) {
      return 'red'
    } else if (_puntos < 25) {
      return 'yellow'
    } else if (_puntos >= 25) {
      return 'green'
    }
  }








  //Traemos la base de datos completa de los registros de todos
  useEffect(() => {
    setStrictFirstRender(2)


    if (strictFirstRender === 2) {
      const loadingSwal = Swal.fire({ // Pop up de cargando
        title: 'Cargando...',
        icon: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCloseButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      })



      PuntosAPI // Consultamos DB donde tenemos todos los asesores
        .read("Realizados", {
          authentication: {
            username: process.env.REACT_APP_API_REALIZADOS_USERNAME, password: process.env.REACT_APP_API_REALIZADOS_PASSSWORD
          }
        })
        .then(Realizados => {
          setRealizadosDB(Realizados)
          loadingSwal.close()

        })
        .catch(e => {
          Swal.fire({ // Pop up de cargando
            title: 'Ha ocurrido un error',
            icon: 'error',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCloseButton: false,
          })
        })
    }

  }, [strictFirstRender])





  // consultamos api cada que se selecciona una semana con cualquier asesor
  useEffect(() => {

    //datos del asesor
    const semanaActual = obtenerSemanas("actual") //obteemos semana actual
    const asesorUuid = managerMonitoring ? managerMonitoring.uuid : asesorData.asesorUuid // Uuid del asesor logueado en la sesión actual
    const asesorCua = managerMonitoring ? managerMonitoring.cua : asesorData.asesorCua ?? ""
    const asesorNombre = managerMonitoring ? managerMonitoring.nombre : asesorData.asesorName





    if (RealizadosDB !== null) {
      //buscamos en el uuid de nuestro asesor, que no tenga una semana igual a la actual


      const realizadosActuales = RealizadosDB.find((semana, idx) => (semana.semana === selectedWeek.week && semana.asesor_uuid == asesorUuid))

      if (realizadosActuales) {




        const loadingSwal = Swal.fire({ // Pop up de cargando
          title: 'Cargando semana...',
          icon: 'info',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          showCloseButton: false,
          didOpen: () => {
            Swal.showLoading();
          }
        })
  
  
  
        PuntosAPI // Consultamos DB donde tenemos todos los asesores
          .read("Realizados", {
            authentication: {
              username: process.env.REACT_APP_API_REALIZADOS_USERNAME, password: process.env.REACT_APP_API_REALIZADOS_PASSSWORD
            }
          })
          .then(Realizados => {
            const realizadosGuardados = Realizados.find((semana, idx) => (semana.semana === selectedWeek.week && semana.asesor_uuid == asesorUuid))
            // aunque ya tenga la tabla inicializada aquí vamos a voler a consumir la api para importantes modificaciones hechas dentro de la misma sesion
            const savedData = {
              lunes: JSON.parse(realizadosGuardados?.lunes),
              martes: JSON.parse(realizadosGuardados?.martes),
              miercoles: JSON.parse(realizadosGuardados?.miercoles),
              jueves: JSON.parse(realizadosGuardados?.jueves),
              viernes: JSON.parse(realizadosGuardados?.viernes)
            };
            const savedHeaders = {
              uuid: realizadosGuardados?.semana_uuid,
              semana: realizadosGuardados?.semana
            }


            setRealizados(savedData)
            setHeadersWeek(savedHeaders)

            loadingSwal.close()
  
          })
          .catch(e => {
            Swal.fire({ // Pop up de cargando
              title: 'Ha ocurrido un error al cargar la semana',
              icon: 'error',
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              showConfirmButton: false,
              showCloseButton: false,
            })
          })

      } else {

        const loadingSwal = Swal.fire({ // Pop up de cargando
          title: 'Creando tu semana...',
          icon: 'info',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          showCloseButton: false,
          didOpen: () => {
            Swal.showLoading();
          }
        })


        const sinRealizados = { prospectos: 0, citas: 0, entrevistas: 0, solicitudes: 0, pagos: 0 }
        const sinRealizadosFormated = JSON.stringify(sinRealizados)
        const newUuid = uuid();
        const newWeek = {
          "semana_uuid": newUuid,
          "semana": selectedWeek.week,
          "asesor_nombre": asesorNombre,
          "asesor_cua": asesorCua,
          "asesor_uuid": asesorUuid,
          "lunes": sinRealizadosFormated,
          "martes": sinRealizadosFormated,
          "miercoles": sinRealizadosFormated,
          "jueves": sinRealizadosFormated,
          "viernes": sinRealizadosFormated,
        }
        const newWeekHeaders = {
          uuid: newUuid,
          semana: selectedWeek.week
        }

        PuntosAPI // Consultamos DB donde tenemos todos los asesores, conseguimos el uuid de la semana actual
          .append(
            "Realizados",
            [newWeek],
            { authentication: { username: process.env.REACT_APP_API_REALIZADOS_USERNAME, password: process.env.REACT_APP_API_REALIZADOS_PASSSWORD } }
          )
          .then(data => {
            setHeadersWeek(newWeekHeaders)
            setRealizadosDB([...RealizadosDB, newWeek])
            loadingSwal.close()

          })
          .catch(e => {
            Swal.fire({ // Pop up de cargando
              title: 'Ha ocurrido un error',
              icon: 'error',
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              showConfirmButton: false,
              showCloseButton: false,
            })
          })


      }





    }
  }, [RealizadosDB, selectedWeek])






  return (
    <table className="TablaRealizados">
      <thead>
        <tr>
          <th rowSpan="2">Valor en puntos</th>
          <th colSpan="2">Lunes</th>
          <th colSpan="2">Martes</th>
          <th colSpan="2">Miércoles</th>
          <th colSpan="2">Jueves</th>
          <th colSpan="2">Viernes</th>
        </tr>
        <tr>
          {Object.keys(initialData).map(day => (
            <React.Fragment key={day}>
              <th>Realizado</th>
              <th>Puntos</th>
            </React.Fragment>
          ))}
        </tr>
      </thead>
      <tbody>
        {Object.keys(points).map((type, idx) => (
          <tr key={type}>
            <td className='prettier'>{getPointsName(idx)}
              <br />
              <span className="points-value">{points[type]}</span>
            </td>
            {Object.keys(initialData).map(day => (
              <React.Fragment key={`${day}-${type}`}>
                <td>
                  <input
                    className={`${managerMonitoring ? "inputDisabled" : ""}`}
                    disabled={managerMonitoring}
                    type="number"
                    value={realizados[day][type] || ""}
                    onChange={(e) => handleChange(e, day, type)}
                    onBlur={(e) => handleBlur(e, day, type)}
                  />
                </td>
                <td>{realizados[day][type] * points[type]}</td>
              </React.Fragment>
            ))}
          </tr>
        ))}
        <tr>
          <td className='totalHeader'>Total Puntos</td>
          {Object.keys(initialData).map(day => (
            <td
              key={day}
              colSpan="2"
              className={`total-points ${getPointsColor(calculatePoints(realizados[day]))}`}
            >
              {calculatePoints(realizados[day])}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

export default TablaRealizados;
