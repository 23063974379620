import React from 'react';
import './TotalSemanal.scss';


export default function ({ realizados }) {

  const calculateWeeklyTotal = (type) => {
    return Object.values(realizados).reduce((total, day) => total + (day[type] || 0), 0);
  };

  const weeklyTotals = {
    prospectos: calculateWeeklyTotal('prospectos'),
    citas: calculateWeeklyTotal('citas'),
    entrevistas: calculateWeeklyTotal('entrevistas'),
    solicitudes: calculateWeeklyTotal('solicitudes'),
    pagos: calculateWeeklyTotal('pagos')
  };

  return (
    <table className="TotalSemanal">
      <thead>
        <tr>
          <th>Total semanal</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(weeklyTotals).map((type) => (
          <tr key={type}>
            <td>{type.charAt(0).toUpperCase() + type.slice(1)}</td>
            <td>{weeklyTotals[type]}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};