import React, { useEffect, useState } from 'react';
import './TotalTrimestral.scss';

const defaultData = {
  prospectos: 0,
  citas: 0,
  entrevistas: 0,
  solicitudes: 0,
  pagos: 0
};

const TotalTrimestral = ({ managerMonitoring, asesorData, RealizadosDB, realizados }) => {
  const [trimestralData, setTrimestralData] = useState(defaultData);

  useEffect(() => {
    if (asesorData, RealizadosDB) {

      const getWeeksOfCurrentQuarter = () => {
        const today = new Date();
        const currentMonth = today.getMonth();
        const currentQuarter = Math.floor(currentMonth / 3);
        const firstMonthOfQuarter = currentQuarter * 3;

        const startQuarter = new Date(today.getFullYear(), firstMonthOfQuarter, 1);
        const endQuarter = new Date(today.getFullYear(), firstMonthOfQuarter + 3, 0, 23, 59, 59, 999);

        return { startQuarter, endQuarter };
      };

      const isWeekInCurrentQuarter = (week, quarter) => {
        const [startWeek, endWeek] = week.split('-').map(date => {
          const [day, month, year] = date.split('/').map(Number);
          return new Date(year, month - 1, day);
        });

        return startWeek >= quarter.startQuarter && endWeek <= quarter.endQuarter;
      };

      const calculateTrimestralData = () => {
        const quarter = getWeeksOfCurrentQuarter();
        let aggregatedData = { ...defaultData };

        // estas inspeccionando un asesor siendo manager, o estas ingresando como asesor normal?
        const monitoring = managerMonitoring ? managerMonitoring.uuid : asesorData.asesorUuid

        RealizadosDB.forEach(record => {
          if (record.asesor_uuid === monitoring && isWeekInCurrentQuarter(record.semana, quarter)) {
            ['lunes', 'martes', 'miercoles', 'jueves', 'viernes'].forEach(day => {
              const dayData = JSON.parse(record[day]);

              aggregatedData.prospectos += dayData.prospectos || 0;
              aggregatedData.citas += dayData.citas || 0;
              aggregatedData.entrevistas += dayData.entrevistas || 0;
              aggregatedData.solicitudes += dayData.solicitudes || 0;
              aggregatedData.pagos += dayData.pagos || 0;
            });
          }
        });

        setTrimestralData(aggregatedData);
      };

      calculateTrimestralData();
    }

  }, [asesorData, RealizadosDB, realizados]);

  return (asesorData && RealizadosDB &&
    <table className="TotalTrimestral">
      <thead>
        <tr>
          <th>Total Trimestral</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(trimestralData).map((type) => (
          <tr key={type}>
            <td>{type.charAt(0).toUpperCase() + type.slice(1)}</td>
            <td>{trimestralData[type]}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TotalTrimestral;
