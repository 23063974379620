import React, { useEffect, useState } from "react";

// Components
import AsesoresTable from "../../Components/AsesoresTable/AsesoresTable";
import WeeksSelect from "../../Components/WeeksSelect/WeeksSelect";
import TablaRealizados from "../../Components/TablaRealizados/TablaRealizados";
import TotalTrimestral from "../../Components/TotalTrimestral/TotalTrimestral"
import TotalSemanal from "../../Components/TotalSemanal/TotalSemanal";

//imgs
import logotipo from "../../Images/ValoretLogotipoEnfasis.png";


// db connection
import SteinStore from "stein-js-client";

//Styles
import "./ManagerView.scss"

// pop up library
import Swal from 'sweetalert2';


//util
import getInicioFinSemanaActual from "../../Utils/getInicioFinSemanaActual ";
// Función para formatear la fecha en DD/MM/YYYY
const formatDate = (date) => {
  // Función para obtener los nombres de los meses en español
  const meses = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];

  const day = date.getDate().toString().padStart(2, '0');
  const month = meses[date.getMonth()];
  return `${day}/${month}`;
};
// Función para formatear la fecha en DD/MM/YYYY
const parseDate = (date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear()
  return [day, month, year];
};






export default function ({
  title,
  cua,
  asesorData,
  basesDeDatos
}) {


  //default data
  const defaultRealizados = {
    lunes: { prospectos: 0, citas: 0, entrevistas: 0, solicitudes: 0, pagos: 0 },
    martes: { prospectos: 0, citas: 0, entrevistas: 0, solicitudes: 0, pagos: 0 },
    miercoles: { prospectos: 0, citas: 0, entrevistas: 0, solicitudes: 0, pagos: 0 },
    jueves: { prospectos: 0, citas: 0, entrevistas: 0, solicitudes: 0, pagos: 0 },
    viernes: { prospectos: 0, citas: 0, entrevistas: 0, solicitudes: 0, pagos: 0 }
  };



  // DB 
  const AsesoresAPI = new SteinStore(
    "https://api.steinhq.com/v1/storages/661deda64a642363121cafb2"
  );
  const [AsesoresDB, setAsesoresDB] = useState(null)

  const [strictFirstRender, setStrictFirstRender] = useState(1)
  const [asesorSeleccionado, setAsesorSeleccionado] = useState(null)



  // para renderizar los mismos componentes que el aessor view necesitamos estas variables
  const [selectedWeek, setSelectedWeek] = useState(null)
  const [RealizadosDB, setRealizadosDB] = useState(null)
  const [realizados, setRealizados] = useState(defaultRealizados)
  const realizadosParams = {
    RealizadosDB, setRealizadosDB,
    realizados, setRealizados
  }





  //Obtenemos los asesores para despues mostrarlos en la tabla
  useEffect(() => {
    setStrictFirstRender(2)

    if (strictFirstRender == 2) {
      const loadingSwal = Swal.fire({ // Pop up de cargando
        title: 'Cargando...',
        icon: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCloseButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      })




      AsesoresAPI // Consultamos DB donde tenemos todos los asesores
        .read("Asesores-CRM-Valoret", {
          authentication: {
            username: process.env.REACT_APP_API_ASESORES_USERNAME, password: process.env.REACT_APP_API_ASESORES_PASSSWORD
          }
        })
        .then(data => {
          setAsesoresDB(data)
          loadingSwal.close()
        })
        .catch(e => {
          Swal.fire({ // Pop up de cargando
            title: 'Ha ocurrido un error',
            icon: 'error',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCloseButton: false,
          })
        })
    }

  }, [strictFirstRender])

  //seleccionamos semana actual por defecto
  useEffect(() => {
    if (strictFirstRender == 2) {
      // Obtener la semana actual
      const currentWeekRange = getInicioFinSemanaActual();
      const [startDay, startMonth, startYear] = parseDate(currentWeekRange.inicioSemana)
      const [endDay, endMonth, endYear] = parseDate(currentWeekRange.finSemana)


      const formattedCurrentWeek = {
        week: `${startDay}/${startMonth}/${startYear}-${endDay}/${endMonth}/${endYear}`,
        start: currentWeekRange.inicioSemana,
        end: currentWeekRange.finSemana,
        display: `${formatDate(currentWeekRange.inicioSemana)} al ${formatDate(currentWeekRange.finSemana)}`
      };


      setSelectedWeek(formattedCurrentWeek)
    }
  }, [strictFirstRender, asesorSeleccionado])





  return (<div className="AsesorView">

    {/* tabla principal */}
    <section className={`container-wrap`}>
      <div className="container">
        <div style={{ height: "15px" }} />

        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <img src={logotipo} className="appPageLogo" />

          <h1 className="h1" style={{ textAlign: "center" }}>
            Monitorea tus asesores
          </h1>
        </div>


        <div style={{ height: "25px" }} />

        {
          AsesoresDB &&
          <AsesoresTable
            asesoresDB={AsesoresDB}
            asesorSeleccionado={asesorSeleccionado}
            setAsesorSeleccionado={setAsesorSeleccionado}
          />
        }

        <div style={{ height: "50px" }} />
      </div>
    </section>













    {
      asesorSeleccionado &&
      <React.Fragment>
        <div style={{ height: "15px" }} />

        {/* tabla principal */}
        <section className={`container-wrap`}>
          <div className="container">
            <div style={{ height: "15px" }} />

            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

              <h1 className="h1" style={{ textAlign: "center" }}>
                Puntos semanales
              </h1>

              <WeeksSelect managerMonitoring={asesorSeleccionado} {...realizadosParams} asesorData={asesorData} selectedWeek={selectedWeek} setSelectedWeek={setSelectedWeek} />
            </div>


            <div style={{ height: "25px" }} />


            <TablaRealizados managerMonitoring={asesorSeleccionado} {...realizadosParams} asesorData={asesorData} selectedWeek={selectedWeek} />


            <div style={{ height: "50px" }} />
          </div>
        </section>




        <div style={{ height: "20px" }} />




        {/* tablas de totales */}
        <section className={`container-wrap`}>
          <div className="container">
            <div style={{ height: "35px" }} />

            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

              <h1 className="h1" style={{ textAlign: "center" }}>
                Realizados Totales
              </h1>
            </div>


            <div style={{ height: "25px" }} />


            <div className="doubleTable">
              <TotalSemanal managerMonitoring={asesorSeleccionado} {...realizadosParams} />

              {/* el total trimestral todavía no lo podemos actualizar en tiempo real, es necesario recargar la página */}
              <TotalTrimestral managerMonitoring={asesorSeleccionado} {...realizadosParams} asesorData={asesorData} />
            </div>


            <div style={{ height: "60px" }} />
          </div>
        </section>
      </React.Fragment>
    }



  </div>);
}
