import React, { useState, useEffect } from 'react';
import './WeeksSelect.scss';
import getInicioFinSemanaActual from '../../Utils/getInicioFinSemanaActual ';

// Función para obtener los nombres de los meses en español
const meses = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];

// Función para formatear la fecha en DD/MM/YYYY
const formatDate = (date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = meses[date.getMonth()];
  return `${day}/${month}`;
};
// Función para formatear la fecha en DD/MM/YYYY
const parseDate = (date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear()
  return [day, month, year];
};

const WeeksSelect = ({ managerMonitoring = false, asesorData, RealizadosDB, selectedWeek, setSelectedWeek }) => {
  const [weeks, setWeeks] = useState([]);



  // al seleccionar la semana, tiene que coincidir con el formato que yo doy en base de datos porque ahí es donde la voy a buscar
  // con el mismo formato que seleccionaron


  useEffect(() => {
    if (asesorData && RealizadosDB && selectedWeek && setSelectedWeek) {

      // Obtener la semana actual
      const currentWeekRange = getInicioFinSemanaActual();
      const [startDay, startMonth, startYear] = parseDate(currentWeekRange.inicioSemana)
      const [endDay, endMonth, endYear] = parseDate(currentWeekRange.finSemana)


      const formattedCurrentWeek = {
        week: `${startDay}/${startMonth}/${startYear}-${endDay}/${endMonth}/${endYear}`,
        start: currentWeekRange.inicioSemana,
        end: currentWeekRange.finSemana,
        display: `${formatDate(currentWeekRange.inicioSemana)} al ${formatDate(currentWeekRange.finSemana)}`
      };


      // estas inspeccionando un asesor siendo manager, o estas ingresando como asesor normal?
      const monitoring = managerMonitoring ? managerMonitoring.uuid : asesorData.asesorUuid

      // Obtener las semanas registradas en RealizadosDB para el asesor
      const asesorWeeks = RealizadosDB
        .filter(record => record.asesor_uuid === monitoring)
        .map(record => {
          const [start, end] = record.semana.split('-');

          const [startDay, startMonth, startYear] = start.split("/")
          const [endDay, endMonth, endYear] = end.split("/")
          const startDate = new Date(startYear, parseInt(startMonth) - 1, startDay)
          const endDate = new Date(endYear, parseInt(endMonth) - 1, endDay)
          endDate.setHours(23, 59, 59, 999)



          return {
            week: `${startDay}/${startMonth}/${startYear}-${endDay}/${endMonth}/${endYear}`,
            start: startDate,
            end: endDate,
            display: `${formatDate(startDate)} al ${formatDate(endDate)}`
          };
        })

      // Construir la lista de semanas a mostrar
      let weeksToShow = [];

      if (asesorWeeks.findIndex(week => week.week == "01/07/2024-07/07/2024") === -1) {
        // if not found PRIMER SEMANA DE JULIO
        const [start, end] = "01/07/2024-07/07/2024".split('-');

        const [startDay, startMonth, startYear] = start.split("/")
        const [endDay, endMonth, endYear] = end.split("/")
        const startDate = new Date(startYear, parseInt(startMonth) - 1, startDay)
        const endDate = new Date(endYear, parseInt(endMonth) - 1, endDay)
        endDate.setHours(23, 59, 59, 999)

        asesorWeeks.push({
          week: `${startDay}/${startMonth}/${startYear}-${endDay}/${endMonth}/${endYear}`,
          start: startDate,
          end: endDate,
          display: `${formatDate(startDate)} al ${formatDate(endDate)}`
        })
      } 
      if(asesorWeeks.findIndex(week => week.week == "08/07/2024-14/07/2024") === -1) {
        // if not found SEMANA DE JULIO
        const [start, end] = "08/07/2024-14/07/2024".split('-');

        const [startDay, startMonth, startYear] = start.split("/")
        const [endDay, endMonth, endYear] = end.split("/")
        const startDate = new Date(startYear, parseInt(startMonth) - 1, startDay)
        const endDate = new Date(endYear, parseInt(endMonth) - 1, endDay)
        endDate.setHours(23, 59, 59, 999)

        asesorWeeks.push({
          week: `${startDay}/${startMonth}/${startYear}-${endDay}/${endMonth}/${endYear}`,
          start: startDate,
          end: endDate,
          display: `${formatDate(startDate)} al ${formatDate(endDate)}`
        })
      }

      asesorWeeks.sort((a, b) => b.end - a.start); // Ordenar de más reciente a más antigua



      if (asesorWeeks.find(week => week.week == formattedCurrentWeek.week)) {
        weeksToShow = [...asesorWeeks]
      } else {
        weeksToShow = [formattedCurrentWeek, ...asesorWeeks]
      }

      setWeeks(weeksToShow);

      // Establecer la semana seleccionada por defecto si no hay una seleccionada aún
      if (!selectedWeek && weeksToShow.length > 0) {
        setSelectedWeek(weeksToShow[0]);
      }
    }
  }, [managerMonitoring, asesorData, RealizadosDB, selectedWeek, setSelectedWeek]);

  // Manejar el cambio de semana seleccionada
  const handleWeekChange = (event) => {
    const selectedWeek = weeks.find(week => week.week === event.target.value);
    setSelectedWeek(selectedWeek);
  };


  return (
    <div className="WeeksSelect">
      <label htmlFor="weekSelector">Seleccione una Semana:</label>
      <select id="weekSelector" value={selectedWeek ? selectedWeek.week : ''} onChange={handleWeekChange}>
        {weeks.map(week => (
          <option key={week.week} value={week.week}>
            {week.display}
          </option>

        ))}
      </select>
    </div>
  );
};

export default WeeksSelect;
