import { useEffect, useState } from "react";

// Components
import TablaRealizados from "../../Components/TablaRealizados/TablaRealizados";
import TotalSemanal from "../../Components/TotalSemanal/TotalSemanal";
import WeeksSelect from "../../Components/WeeksSelect/WeeksSelect";

//imgs
import logotipo from "../../Images/ValoretLogotipoEnfasis.png";


// db connection
import SteinStore from "stein-js-client";

//Styles
import "./AsesorView.scss"

//util
import TotalTrimestral from "../../Components/TotalTrimestral/TotalTrimestral";
import getInicioFinSemanaActual from "../../Utils/getInicioFinSemanaActual ";




// Función para formatear la fecha en DD/MM/YYYY
const formatDate = (date) => {
  // Función para obtener los nombres de los meses en español
  const meses = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];

  const day = date.getDate().toString().padStart(2, '0');
  const month = meses[date.getMonth()];
  return `${day}/${month}`;
};
// Función para formatear la fecha en DD/MM/YYYY
const parseDate = (date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');  
  const year = date.getFullYear()
  return [day, month, year];
};





const defaultRealizados = {
  lunes: { prospectos: 0, citas: 0, entrevistas: 0, solicitudes: 0, pagos: 0 },
  martes: { prospectos: 0, citas: 0, entrevistas: 0, solicitudes: 0, pagos: 0 },
  miercoles: { prospectos: 0, citas: 0, entrevistas: 0, solicitudes: 0, pagos: 0 },
  jueves: { prospectos: 0, citas: 0, entrevistas: 0, solicitudes: 0, pagos: 0 },
  viernes: { prospectos: 0, citas: 0, entrevistas: 0, solicitudes: 0, pagos: 0 }
};


export default function ({
  title,
  cua,
  asesorData,
  basesDeDatos
}) {


  const [strictFirstRender, setStrictFirstRender] = useState(1)
  const [selectedWeek, setSelectedWeek] = useState(null) 
  const [RealizadosDB, setRealizadosDB] = useState(null) 
  const [realizados, setRealizados] = useState(defaultRealizados)
  const realizadosParams = {
    RealizadosDB, setRealizadosDB,
    realizados, setRealizados
  }
  
  
  


  
  
  
  //seleccionamos semana actual por defecto
  useEffect(() => {
    setStrictFirstRender(2)

    if(strictFirstRender == 2) { 
      // Obtener la semana actual
      const currentWeekRange = getInicioFinSemanaActual();
      const [startDay, startMonth, startYear] = parseDate(currentWeekRange.inicioSemana)
      const [endDay, endMonth, endYear] = parseDate(currentWeekRange.finSemana)


      const formattedCurrentWeek = {
        week: `${startDay}/${startMonth}/${startYear}-${endDay}/${endMonth}/${endYear}`,
        start: currentWeekRange.inicioSemana,
        end: currentWeekRange.finSemana,
        display: `${formatDate(currentWeekRange.inicioSemana)} al ${formatDate(currentWeekRange.finSemana)}`
      };
      

      setSelectedWeek(formattedCurrentWeek)
    }
  }, [strictFirstRender])
  











  return (<div className="AsesorView">

    {/* tabla principal */}
    <section className={`container-wrap`}>
      <div className="container">
        <div style={{ height: "15px" }} />

        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <img src={logotipo} className="appPageLogo" />

          <h1 className="h1" style={{ textAlign: "center" }}>
            ¡Gestiona tus puntos!
          </h1>

          <WeeksSelect {...realizadosParams} asesorData={asesorData} selectedWeek={selectedWeek} setSelectedWeek={setSelectedWeek} />
        </div>


        <div style={{ height: "25px" }} />

        
        <TablaRealizados {...realizadosParams} asesorData={asesorData} selectedWeek={selectedWeek} />


        <div style={{ height: "50px" }} />
      </div>
    </section>




    <div style={{ height: "20px" }} />




    {/* tablas de totales */}
    <section className={`container-wrap`}>
      <div className="container">
        <div style={{ height: "35px" }} />

        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

          <h1 className="h1" style={{ textAlign: "center" }}>
            Realizados Totales
          </h1>
        </div>


        <div style={{ height: "25px" }} />
        

        <div className="doubleTable">
          <TotalSemanal {...realizadosParams} />

          {/* el total trimestral todavía no lo podemos actualizar en tiempo real, es necesario recargar la página */}
          <TotalTrimestral {...realizadosParams} asesorData={asesorData} /> 
        </div>

    
        <div style={{ height: "60px" }} />
      </div>
    </section>
    


  </div>);
}